import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  photos: string[];
  toggleModal: () => void;
  setImage: (image: string) => void;
}

const CareerOurLife: FC<IProps> = ({
  title,
  photos,
  toggleModal,
  setImage,
}) => (
  <div className={styles.wrap}>
    <div className={styles.title}>{title}</div>
    <div className={styles.photos}>
      {photos.map((photo, index) => (
        <div className={styles.photo} key={`ourLife-${index + 1}`}>
          <img src={photo} alt="" />
          <button
            className={styles.button}
            type="button"
            onClick={() => {
              toggleModal();
              setImage(photo);
            }}
          >
            <div>
              <img
                className={styles.expand__icon}
                src="/career/expand.svg"
                alt=""
              />
            </div>
          </button>
        </div>
      ))}
    </div>
  </div>
);

export default CareerOurLife;
