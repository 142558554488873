import React, { FC } from 'react';
import LinkCard from '../_common/LinkCard';
import styles from './styles.module.scss';

interface IProps {
  cards: {
    icon: string;
    title: string;
    description: string;
    url: string;
  }[];
}

const CareerLinks: FC<IProps> = ({ cards }) => (
  <div className={styles.wrap}>
    <div className={styles.background} />
    <div className={styles.cardsContainer}>
      {cards.map((card, index) => (
        <LinkCard
          key={`linkCard-${index + 1}`}
          icon={card.icon}
          title={card.title}
          description={card.description}
          url={card.url}
        />
      ))}
    </div>
  </div>
);

export default CareerLinks;
