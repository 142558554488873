import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  text: string;
  name: string;
  position: string;
  photo: string;
}

const Review: FC<IProps> = ({ text, name, position, photo }) => (
  <div className={styles.wrap}>
    <div className={styles.quote}>
      <img src="/career/quote.svg" alt="" />
    </div>
    <div className={styles.text}>{text}</div>
    <div className={styles.footer}>
      <div className={styles.photo}>
        <img src={photo} alt="" />
      </div>
      <div className={styles.personalDetails}>
        <div className={styles.name}>{name}</div>
        <div className={styles.position}>{position}</div>
      </div>
    </div>
  </div>
);

export default Review;
