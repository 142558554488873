import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { Vacancy } from '../_common/Vacancy';
import { changePath } from '../../../utils/hooks';

interface IProps {
  title: string;
  positions: {
    id: string;
    frontmatter: {
      type: string;
      title: string;
      business: string;
      quality: string;
      details: string;
    };
    html: string;
    fileAbsolutePath: string;
  }[];
}

const CareerVacancies: FC<IProps> = ({ title: titleSection, positions }) => {
  const [activeVacancies, setActiveVacancies] = useState([0, 1, 2]);
  const [activeMobile, setActiveMobile] = useState(0);

  const disabledNext =
    activeVacancies[activeVacancies.length - 1] === positions.length - 1;
  const disabledNextMobile = activeMobile === positions.length - 1;
  const disabledBack = activeVacancies[0] === 0;
  const disabledBackMobile = activeMobile === 0;

  const goNext = () => {
    if (disabledNext) {
      return;
    }
    setActiveVacancies(
      activeVacancies.map((number, i) => activeVacancies[i] + 1)
    );
  };
  const goBack = () => {
    if (disabledBack) {
      return;
    }
    setActiveVacancies(
      activeVacancies.map((number, i) => activeVacancies[i] - 1)
    );
  };

  const goNextMobile = () => {
    if (disabledNextMobile) {
      return;
    }
    setActiveMobile(activeMobile + 1);
  };

  const goBackMobile = () => {
    if (disabledBackMobile) {
      return;
    }
    setActiveMobile(activeMobile - 1);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{titleSection}</div>
        <div className={styles.buttonsContainer}>
          <button
            type="button"
            onClick={goBack}
            className={`${styles.button} ${
              disabledBack ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-left.svg" alt="" />
          </button>
          <button
            type="button"
            onClick={goNext}
            className={`${styles.button} ${
              disabledNext ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-right.svg" alt="" />
          </button>
        </div>
        <div className={styles.buttonsContainerMobile}>
          <button
            type="button"
            onClick={goBackMobile}
            className={`${styles.button} ${
              disabledBackMobile ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-left.svg" alt="" />
          </button>
          <button
            type="button"
            onClick={goNextMobile}
            className={`${styles.button} ${
              disabledNextMobile ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-right.svg" alt="" />
          </button>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {activeVacancies.map((item) => (
          <div key={positions[item].id} className={styles.card}>
            <Vacancy
              index={item}
              type={positions[item].frontmatter.type}
              quality={positions[item].frontmatter.quality}
              description={positions[item].html}
              title={positions[item].frontmatter.title}
              business={positions[item].frontmatter.business}
              slug={changePath(positions[item].fileAbsolutePath)}
            />
          </div>
        ))}
      </div>

      <div className={styles.cardContainerMobile}>
        <div className={styles.card}>
          <Vacancy
            index={activeMobile}
            type={positions[activeMobile].frontmatter.type}
            quality={positions[activeMobile].frontmatter.quality}
            description={positions[activeMobile].html}
            title={positions[activeMobile].frontmatter.title}
            business={positions[activeMobile].frontmatter.business}
            slug={changePath(positions[activeMobile].fileAbsolutePath)}
            key={positions[activeMobile].id}
          />
        </div>
      </div>
    </div>
  );
};

export default CareerVacancies;
