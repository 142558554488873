const TYPE_IMAGES = {
  analytic: '/vacancy/analytic.svg',
  android: '/vacancy/android.svg',
  designer: '/vacancy/designer.svg',
  frontend: '/vacancy/frontend.svg',
  other: '/vacancy/other.svg',
  qa: '/vacancy/qa.svg',
  pm: '/vacancy/qa.svg',
  sales: '/vacancy/analytic.svg',
};

export const getVacancyIcon = (type: string) =>
  type && Object.prototype.hasOwnProperty.call(TYPE_IMAGES, type)
    ? TYPE_IMAGES[type as keyof typeof TYPE_IMAGES]
    : TYPE_IMAGES.other;
