import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  icon: string;
  title: string;
  description: string;
}

const Reason: FC<IProps> = ({ icon, title, description }) => (
  <div className={styles.wrap}>
    <div className={styles.imageContainer}>
      <img src={icon} alt="" className={styles.image} />
    </div>
    <div className={styles.textContainer}>
      <div className={styles.titleContainer}>
        <div>
          <img src={icon} alt="" className={styles.imageMobile} />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
);

export default Reason;
