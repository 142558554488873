import React, { FC, useState } from 'react';
import Review from '../_common/Review';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  reviews: {
    text: string;
    name: string;
    position: string;
    photo: string;
  }[];
}

const CareerTestimonials: FC<IProps> = ({ title, reviews }) => {
  const [activeMobile, setActiveMobile] = useState(0);

  const disabledNextMobile = activeMobile === reviews.length - 1;
  const disabledBackMobile = activeMobile === 0;

  const goNextMobile = () => {
    if (disabledNextMobile) {
      return;
    }
    setActiveMobile(activeMobile + 1);
  };

  const goBackMobile = () => {
    if (disabledBackMobile) {
      return;
    }
    setActiveMobile(activeMobile - 1);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.buttonsContainerMobile}>
          <button
            type="button"
            onClick={goBackMobile}
            className={`${styles.button} ${
              disabledBackMobile ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-left.svg" alt="" />
          </button>
          <button
            type="button"
            onClick={goNextMobile}
            className={`${styles.button} ${
              disabledNextMobile ? styles.button__disabled : ''
            }`}
          >
            <img src="/career/chevron-right.svg" alt="" />
          </button>
        </div>
      </div>
      <div className={styles.reviews}>
        {reviews.map((review, index) => (
          <Review
            key={`review-${index + 1}`}
            text={review.text}
            name={review.name}
            position={review.position}
            photo={review.photo}
          />
        ))}
      </div>

      <div className={styles.reviewsMobile}>
        <Review
          text={reviews[activeMobile].text}
          name={reviews[activeMobile].name}
          position={reviews[activeMobile].position}
          photo={reviews[activeMobile].photo}
        />
      </div>
    </div>
  );
};

export default CareerTestimonials;
