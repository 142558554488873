import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  subtitle: string;
  stagesHeader: string;
  stages: string[];
  conclusion: string;
}

const CareerHowToJoin: FC<IProps> = ({
  title,
  subtitle,
  stagesHeader,
  stages,
  conclusion,
}) => (
  <div className={styles.wrap}>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
    <div className={styles.stagesHeader}>{stagesHeader}</div>
    <ul className={styles.list}>
      {stages.map((stage, index) => (
        <li className={styles.listItem} key={`stage${index + 1}`}>
          {stage}
        </li>
      ))}
    </ul>
    <div className={styles.conclusion}>
      <span dangerouslySetInnerHTML={{ __html: conclusion }} />
    </div>
  </div>
);

export default CareerHowToJoin;
