/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../templates/MainLayout';
import { ICareerQuery } from '../../queries/career/types';
import CareerAbout from '../../components/career/CareerAbout';
import CareerComeToUs from '../../components/career/CareerComeToUs';
import { IPositionsQuery } from '../../queries/positions/types';
import CareerVacancies from '../../components/career/CareerVacancies';
import CareerHowToJoin from '../../components/career/CareerHowToJoin';
import CareerLinks from '../../components/career/CareerLinks';
import CareerTestimonials from '../../components/career/CareerTestimonials';
import CareerOurLife from '../../components/career/CareerOurLife';
import { ImageModal } from '../../components/career/_common/ImageModal';

type IProps = PageProps<ICareerQuery & IPositionsQuery, { locale: string }>;

const CareerPage: FC<IProps> = ({ data, pageContext: { locale = 'ru' } }) => {
  const career = useMemo(
    () => data.career.edges.find((edge) => edge.node),
    [data.career]
  );

  const positions = useMemo(
    () => data.positions.edges.map((edge) => edge.node),
    [data.positions]
  );

  const overview = career?.node.frontmatter.overview;
  const comeToUs = career?.node.frontmatter.comeToUs;
  const vacancies = career?.node.frontmatter.vacancies;
  const howToJoin = career?.node.frontmatter.howToJoin;
  const links = career?.node.frontmatter.links;
  const testimonials = career?.node.frontmatter.testimonials;
  const ourLife = career?.node.frontmatter.ourLife;

  const [isModalShown, setIsModalShown] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const setImage = useCallback(
    (image) => setModalImage(image),
    [modalImage, setModalImage]
  );

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  return (
    <MainLayout locale={locale} title="menu.career">
      {overview && overview.show && (
        <CareerAbout
          background={overview.background}
          title={overview.title}
          descriptionParagraphs={overview.descriptionParagraphs}
          features={overview.features}
          conclusion={overview.conclusion}
        />
      )}

      {comeToUs && comeToUs.show && (
        <CareerComeToUs title={comeToUs.title} reasons={comeToUs.reasons} />
      )}

      {vacancies && vacancies.show && (
        <CareerVacancies title={vacancies.title} positions={positions} />
      )}

      {howToJoin && howToJoin.show && (
        <CareerHowToJoin
          title={howToJoin.title}
          subtitle={howToJoin.subtitle}
          stagesHeader={howToJoin.stagesHeader}
          stages={howToJoin.stages}
          conclusion={howToJoin.conclusion}
        />
      )}

      {links && links.show && <CareerLinks cards={links.cards} />}

      {testimonials && testimonials.show && (
        <CareerTestimonials
          title={testimonials.title}
          reviews={testimonials.reviews}
        />
      )}

      {ourLife && ourLife.show && (
        <CareerOurLife
          title={ourLife.title}
          photos={ourLife.photos}
          toggleModal={toggleModal}
          setImage={setImage}
        />
      )}
      {isModalShown && (
        <ImageModal onClose={toggleModal} modalImage={modalImage} />
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query {
    career: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/career/content/career/" } }
    ) {
      ...CareerFields
    }

    positions: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vacancies/positions/" } }
    ) {
      ...PositionsFields
    }
  }
`;

export default CareerPage;
