import React, { FC } from 'react';
import styles from './styles.module.scss';
import Reason from '../_common/Reason';

interface IProps {
  title: string;
  reasons: {
    icon: string;
    title: string;
    description: string;
  }[];
}

const CareerComeToUs: FC<IProps> = ({ title, reasons }) => (
  <div className={styles.wrap}>
    <div className={styles.title}>{title}</div>
    <div className={styles.reasons}>
      {reasons.map((reason) => (
        <Reason
          key={reason.title}
          icon={reason.icon}
          title={reason.title}
          description={reason.description}
        />
      ))}
    </div>
  </div>
);

export default CareerComeToUs;
