import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  icon: string;
  title: string;
  description: string;
  url: string;
}

const LinkCard: FC<IProps> = ({ icon, title, description, url }) => (
  <a href={url} target="_blank" rel="noreferrer" className={styles.wrap}>
    <div className={styles.iconsLine}>
      <div className={styles.iconContainer}>
        <div
          className={styles.icon}
          style={{
            maskImage: `url(${icon})`,
            WebkitMaskImage: `url(${icon})`,
          }}
        />
      </div>
      <div className={styles.arrow}>
        <img src="/career/arrow-right.svg" alt="" />
      </div>
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
  </a>
);
export default LinkCard;
