/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import { useCloseOnEscape } from '../../../../constants/hooks';
import { Modal } from '../../../main/Modal';
import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  modalImage: string;
}

const ImageModal: FC<IProps> = ({ onClose, modalImage }) => {
  useCloseOnEscape(onClose);

  return (
    <Modal onClose={onClose}>
      <div className={styles.wrap}>
        <img src={modalImage} alt="" className={styles.image} />
        <button className={styles.close} type="button" onClick={onClose}>
          <img src="/career/close.svg" alt="" />
        </button>
      </div>
    </Modal>
  );
};

export { ImageModal };
