import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  background: {
    image: string;
    color: string;
  };
  title: string;
  descriptionParagraphs: string[];
  features: string[];
  conclusion: string;
}

const CareerAbout: FC<IProps> = ({
  background,
  title,
  descriptionParagraphs,
  features,
  conclusion,
}) => {
  const backgroundImage = `url('${background.image}')`;

  return (
    <div
      style={{
        background: `${backgroundImage} no-repeat center center`,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        backgroundSize: 'cover',
      }}
      className={styles.wrap}
    >
      <div
        className={styles.overlayColor}
        style={{ backgroundColor: background.color }}
      />
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        {descriptionParagraphs.map((item, index) => (
          <div
            key={`careerDescriptionParagraph-${index + 1}`}
            className={styles.description}
          >
            {item}
          </div>
        ))}
        <div className={styles.features}>
          <ul className={styles.features__list}>
            {features.map((feature, index) => (
              <li
                key={`careerFeature-${index + 1}`}
                className={styles.features__item}
              >
                <span dangerouslySetInnerHTML={{ __html: feature }} />
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.conclusion}>{conclusion}</div>
      </div>
    </div>
  );
};

export default CareerAbout;
